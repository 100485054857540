import React, { useEffect, useState } from 'react'
import { Typography, message, Spin } from 'antd'
import { Wrapper, Container } from './signup.styled'
import SignupForm from '../../../component/forms/auth/signup'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'

const { Title } = Typography

const Signup = ({ history, match, postSignupVerify, postSignup }) => {
  let uuid = match.params.uuid

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    email: '',
    role: '',
    token: '',
    auth: false,
  })

  useEffect(() => {
    let token = localStorage.getItem('token')
    if (token) message.error('Logout first', 1)
    else {
      postSignupVerify({ uuid }, (bol, { email, role, token }) => {
        setLoading(false)
        if (bol) setData({ email, role, token, auth: true })
      })
    }
  }, [uuid])

  const onSignup = (values) => {
    postSignup(
      {
        ...pick(values, ['email', 'password', 'password2', 'name']),
        role: data.role,
      },
      data.token
    )
      .then((resp) => history.push('/dashboard'))
      .catch((err) => message.error('Signup Failed'))
  }

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  if (loading)
    return (
      <Wrapper>
        <Container>
          <Spin spinning={true} />
        </Container>
      </Wrapper>
    )

  return (
    <Wrapper>
      <Container>
        {data.auth ? (
          <>
            <Title style={{ textTransform: 'capitalize', marginBottom: 32 }} level={3}>
              {`${data.role} Signup`}
            </Title>
            <SignupForm email={data.email} onSignup={onSignup} onError={onError} />
          </>
        ) : (
          <div style={{ color: 'red' }}>error</div>
        )}
      </Container>
    </Wrapper>
  )
}

Signup.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  postSignupVerify: PropTypes.func.isRequired,
  postSignup: PropTypes.func.isRequired,
}

export default Signup
