export default {
  auth: {},
  team: [],
  eachUser: {},
  store: [],
  category: [],
  eachStore: {},
  inventory: {},
  product: {},
  eachProduct: {},
  brand: [],
  scrap: {},
  saleProduct: [],
  eachSaleProduct: {},
}
