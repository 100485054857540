import React, { useEffect } from 'react'
import { Form, Select, Row, Col, Switch, InputNumber, Input } from 'antd'
import { map, isEmpty } from 'lodash'

const { Option } = Select

const FilterForm = ({ form, onFinish, category, brand }) => {
  return (
    <Form layout="vertical" name="inventory-filter-form" form={form} onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="category" label="Filter By Category : ">
            <Select placeholder={'Category'}>
              {map(category || [], (e, i) => {
                if (isEmpty(e.name)) return
                return (
                  <Option key={i} value={e._id}>
                    {`${e.name}`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="brand" label="Filter By Brand : ">
            <Select
              placeholder={'Brand'}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {map(brand || [], (e, i) => {
                if (isEmpty(e.name)) return
                return (
                  <Option key={i} value={e._id}>
                    {`${e.name}`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="price_range" label="Select Price Range : ">
            <Input.Group compact>
              <Form.Item name="min_price">
                <InputNumber
                  disabled
                  placeholder={'Minimum'}
                  style={{ width: 100, textAlign: 'center' }}
                  min={0}
                />
              </Form.Item>

              <Input
                style={{
                  width: 30,
                  borderLeft: 0,
                  borderRight: 0,
                  pointerEvents: 'none',
                  background: '#fff',
                }}
                placeholder="~"
                disabled
              />

              <Form.Item name="max_price">
                <InputNumber
                  disabled
                  placeholder={'Maximum'}
                  min={0}
                  style={{
                    width: 100,
                    textAlign: 'center',
                    borderLeftWidth: 0,
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="no_store"
            label="Not Linked to any store (0 Store) : "
            valuePropName="checked">
            <Switch disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default FilterForm
