import React from 'react'
import { map, capitalize } from 'lodash'
import { Typography, PageHeader } from 'antd'

const { Paragraph, Text } = Typography

const ProductDetail = ({ data }) => {
  let SPECS = Object.keys(data)

  return (
    <ul>
      {map(SPECS, (spec) => (
        <li key={spec}>
          <Paragraph style={{ marginBottom: 0 }}>
            <Text strong>{`${capitalize(spec).replace(/_/g, ' ')} : `}</Text>
            <Text type="secondary">{Object.values(data[spec]).join(', ')}</Text>
          </Paragraph>
        </li>
      ))}
    </ul>
  )
}

export default React.memo(ProductDetail)
