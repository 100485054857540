import React from 'react'
import { Form, Input, Button, Select } from 'antd'
import { map } from 'lodash'

const { Option } = Select

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
}

const BasicForm = ({ onFinish, data, brand, category }) => {
  return (
    <Form
      name="basic-form"
      {...layout}
      style={{ minWidth: 375, padding: '24px 0' }}
      initialValues={{ ...data }}
      onFinish={onFinish}>
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input Title!',
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Brand"
        name="brand"
        rules={[
          {
            required: true,
            message: 'Please Select Brand!',
          },
        ]}>
        <Select
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {map(brand, (data, i) => {
            return (
              <Option key={data._id} value={data._id}>
                {data.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Category"
        name="category"
        rules={[
          {
            required: true,
            message: 'Please Select Category!',
          },
        ]}>
        <Select>
          {map(category, (data, i) => {
            return (
              <Option key={data._id} value={data._id}>
                {data.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name="series" label="Series">
        <Input />
      </Form.Item>

      <Form.Item name="model_number" label="Model Number">
        <Input />
      </Form.Item>

      {/* <Form.Item name="rank" label="Rank">
        <InputNumber disabled />
      </Form.Item> */}

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default React.memo(BasicForm)
