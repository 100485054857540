import React from 'react'
import { Form, Input, Select, DatePicker, Button, InputNumber } from 'antd'

const { Option } = Select

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
}

const AddProduct = ({ onFinish, form }) => {
  return (
    <Form
      name="addSale-form"
      {...layout}
      style={{ minWidth: 375, padding: '24px 0' }}
      form={form}
      onFinish={onFinish}>
      <Form.Item
        name="site"
        label="Site"
        rules={[
          {
            required: true,
            message: 'Please select Site!',
          },
        ]}>
        <Select placeholder="Select Site ...">
          <Option key="Flipkart">Flipkart</Option>
          <Option key="Amazon">Amazon</Option>
          <Option key="Shop ATSC">Shop ATSC</Option>
          <Option key="MI">MI</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="name"
        label="Product Name"
        rules={[
          {
            required: true,
            message: 'Product Name is Required!',
          },
        ]}>
        <Input placeholder="Enter Product Name" />
      </Form.Item>

      <Form.Item name="date" label="Sale Date and Time">
        <DatePicker showTime />
      </Form.Item>

      <Form.Item name="position" label="Position">
        <InputNumber min={0} max={200} />
      </Form.Item>

      <Form.Item name="badge" label="Badge">
        <Input placeholder="Enter Badge.. (Optional)" />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default React.memo(AddProduct)
