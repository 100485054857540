import React, { useState } from 'react'
import { PageHeader, Divider, Tabs, Form } from 'antd'
import ProductForm from './form/productForm'
import { capitalize, pickBy, identity } from 'lodash'

const { TabPane } = Tabs

const TAB = {
  PRODUCT: 'product',
  VARIANT: 'variant',
}

const AddNewSaleProduct = ({ history, postSaleProduct }) => {
  const [currentTab, setTab] = useState(TAB.PRODUCT)
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()

  const onFinish = (value) => {
    let data = pickBy(
      {
        name: value?.name,
        site: value?.site,
        date: value?.date?._d,
        position: value?.position,
        badge: value?.badge,
      },
      identity
    )

    postSaleProduct(data, (bol) => {
      if (bol) history.push('/chromext/sale')
    })
  }

  return (
    <>
      <PageHeader title="Add New Sale Product" onBack={() => history.push('/chromext/sale')} />
      <Divider style={{ marginTop: 12 }} />

      <Tabs type="card" onChange={(e) => setTab(e)} defaultActiveKey={currentTab}>
        <TabPane tab={capitalize(TAB.PRODUCT)} key={TAB.PRODUCT}>
          <ProductForm form={form} type={TAB.PRODUCT} onFinish={onFinish} />
        </TabPane>

        <TabPane tab={capitalize(TAB.VARIANT)} key={TAB.VARIANT} disabled></TabPane>
      </Tabs>
    </>
  )
}

export default AddNewSaleProduct
