import React from 'react'
import { connect } from 'react-redux'
import Product from '../../pages/product'
import qs from 'qs'
import {
  getProduct,
  postProduct,
  patchProduct,
  deleteProduct,
  getCategory,
  getBrand,
} from '../../actions/asyncAction'
import { omit, pick } from 'lodash'

const ProductContainer = (props) => {
  const { history, location } = props

  const onPagination = (page, CB) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    const query = qs.stringify({ ...params, page: page })
    props.getProduct(query, CB)
    history.push(`${location.pathname}?${query}`)
    document.documentElement.scrollTop = 0
  }

  const getProduct = (CB) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    const query = qs.stringify({ ...params, page: 1 })
    props.getProduct(query, CB)
    history.push(`${location.pathname}?${query}`)
    document.documentElement.scrollTop = 0
  }

  const onSearch = (term, CB) => {
    let query = {}
    if (term) query = qs.stringify({ page: 1, term })
    else query = qs.stringify({ page: 1 })

    props.getProduct(query, CB)
    history.push(`${location.pathname}?${query}`)
    document.documentElement.scrollTop = 0
  }

  const onFilterApply = (data, CB) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    const t = { page: 1, ...data, ...pick(params, 'term') }
    const query = qs.stringify(t)
    props.getProduct(query, CB)
    history.push(`${location.pathname}?${query}`)
  }

  const onClearFilter = (CB) => {
    const query = qs.stringify({})
    props.getProduct(query, CB)
    history.push(`${location.pathname}?${query}`)
  }

  return (
    <Product
      {...props}
      getProduct={getProduct}
      onPagination={onPagination}
      onClearFilter={onClearFilter}
      onFilterApply={onFilterApply}
      onSearch={onSearch}
    />
  )
}

const mapStateToProps = (state) => {
  return { product: state.product, category: state.category, brand: state.brand }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postProduct: (data, CB) => dispatch(postProduct(data, CB)),
    patchProduct: (data, CB) => dispatch(patchProduct(data, CB)),
    getProduct: (query, CB) => dispatch(getProduct(query, CB)),
    deleteProduct: (data, CB) => dispatch(deleteProduct(data, CB)),
    getCategory: (CB) => dispatch(getCategory(CB)),
    getBrand: (CB) => dispatch(getBrand(CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer)
