import { connect } from 'react-redux'
import ChromeXTSaleNew from '../../../pages/chromext/sale/new'
import { postSaleProduct } from '../../../actions/asyncAction'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    postSaleProduct: (data, CB) => dispatch(postSaleProduct(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChromeXTSaleNew)
