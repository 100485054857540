import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #636e72;
`

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 4px;
  padding: 24px;
  min-width: 400px;
`

export { Wrapper, Container }
