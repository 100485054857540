const getDate = (data) => {
  const WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const DATE = new Date(data)
  const day = WEEK[DATE.getDay()]
  const date = DATE.getDate()
  const month = MONTHS[DATE.getMonth()]
  const year = DATE.getFullYear()
  const hours = DATE.getHours()
  const minutes = DATE.getMinutes()
  const seconds = DATE.getSeconds()

  return { day, date, month, year, hours, minutes, seconds }
}

const getTitleFromURL = (URL) => {
  if (URL.includes('flipkart.com')) {
    let start = 'https://www.flipkart.com/'
    let last = '/p/'

    let t = URL.substring(URL.indexOf(start) + start.length, URL.length)

    let s = t.substring(0, t.indexOf(last)).replace(/-/g, ' ')

    return s
  } else if (URL.includes('amazon.in')) {
    let start = 'https://www.amazon.in/'
    let last = '/'

    let t = URL.substring(URL.indexOf(start) + start.length, URL.length)

    let s = t.substring(0, t.indexOf(last)).replace(/-/g, ' ')

    return s
  } else if (URL.includes('dell.com')) {
  } else {
    console.log('website not valid')
  }
}

const displayPrice = (price) => {
  let final = price.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
  })

  return final
}

export { getDate, getTitleFromURL, displayPrice }
