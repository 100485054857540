import { connect } from 'react-redux'
import NewProduct from '../../pages/product/new'
import { postProduct, getBrand, getCategory } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { brand: state.brand, category: state.category }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postProduct: (data, CB) => dispatch(postProduct(data, CB)),
    getBrand: (CB) => dispatch(getBrand(CB)),
    getCategory: (CB) => dispatch(getCategory(CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProduct)
