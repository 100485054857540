import React, { useEffect, useState } from 'react'
import { PageHeader, Button, List, Card, Modal, Form, Typography, Popconfirm, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import AddStoreForm from './addForm'
import { pick, lowerCase, isEmpty, filter } from 'lodash'

const { Text } = Typography
const { Search } = Input
const OPERATION = { ADD: 'add', EDIT: 'edit' }

const Stores = ({ postStore, getStore, store, deleteStore, patchStore, history, location }) => {
  const [loading, setLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState()
  const [operation, setOperation] = useState()
  const [selectedID, setSelectedID] = useState()
  const [form] = Form.useForm()

  useEffect(() => {
    getStore((bol) => {
      setLoading(false)
    })
  }, [])

  const onFinish = (values) => {
    switch (operation) {
      case OPERATION.ADD:
        postStore(pick(values, ['name']), (bol) => {
          if (bol) {
            getStore(() => {})
            setModalVisible(false)
            form.resetFields()
          }
        })
        break

      case OPERATION.EDIT:
        patchStore({ _id: selectedID, ...pick(values, ['name']) }, (bol) => {
          if (bol) {
            getStore(() => {})
            setModalVisible(false)
            form.resetFields()
          }
        })
        break
      default:
        break
    }
  }

  const openModal = (operation) => {
    setModalVisible(true)
    setOperation(operation)
  }

  const onDelete = (_id) => {
    deleteStore({ _id }, (bol) => {
      if (bol) getStore(() => {})
    })
  }

  const onEachStores = (history, location, name, id) => {
    let store = lowerCase(name).replace(/\s/g, '-')
    history.push(`${location.pathname}/${id}`)
  }

  const getFilteredData = (term, data) => {
    if (isEmpty(term)) return data
    else return filter(data, (d) => lowerCase(d.name).includes(lowerCase(term)))
  }

  return (
    <>
      <PageHeader
        title="Stores"
        subTitle={`(${getFilteredData(searchTerm, store).length})`}
        extra={[
          <div style={{ display: 'flex' }} key={3}>
            <Search
              style={{ marginRight: 16 }}
              key={1}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button key={2} type="primary" onClick={() => openModal(OPERATION.ADD)}>
              <PlusOutlined /> Add Stores
            </Button>
          </div>,
        ]}
        style={{ borderBottom: '1px solid #eee' }}
      />

      <Card bordered={false}>
        <List
          itemLayout="horizontal"
          dataSource={getFilteredData(searchTerm, store)}
          loading={loading}
          pagination={true}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  key={1}
                  onClick={() => {
                    setSelectedID(item._id)
                    openModal(OPERATION.EDIT)
                    form.setFieldsValue({ name: item.name })
                  }}>
                  Edit
                </Button>,

                <Popconfirm
                  key={2}
                  placement="left"
                  title="Sure to delete?"
                  onConfirm={() => onDelete(item._id)}>
                  <Button type="link" danger>
                    Delete
                  </Button>
                </Popconfirm>,
              ]}>
              <List.Item.Meta
                title={
                  <Typography.Text strong>
                    <a onClick={() => onEachStores(history, location, item.name, item._id)}>
                      {item.name}
                    </a>
                  </Typography.Text>
                }
              />

              <Text strong>{item.count}</Text>
            </List.Item>
          )}
        />
      </Card>

      <Modal
        destroyOnClose
        title={operation === OPERATION.EDIT ? 'Edit Stores' : 'Add New Stores'}
        okText={operation === OPERATION.EDIT ? 'Edit' : 'Add'}
        visible={modalVisible}
        centered
        onCancel={() => {
          setModalVisible(false)
          setSelectedID()
          form.resetFields()
        }}
        onOk={form.submit}>
        <AddStoreForm form={form} onFinish={onFinish} />
      </Modal>
    </>
  )
}

export default Stores
