import React, { Fragment, useState, useEffect } from 'react'
import { Typography, Divider, Button, Modal, Form } from 'antd'
import TopContainer from '../../UI/wrapper/topcontainer'
import { isEmpty, filter, pick } from 'lodash'
import InviteForm from '../../component/forms/team/invite'
import UserTable from './userTable'
import InviteTable from './inviteTable'

const { Title, Text } = Typography

const RoleTagColor = {
  OWNER: { name: 'owner', color: '#54666d' },
  ADMINISTRATOR: { name: 'administrator', color: '#e74c3c' },
  USER: { name: 'user', color: '#27ae60' },
}

const Team = ({ getTeam, team, postAuthInvite, history, location, auth, deleteTempAuth }) => {
  const [loading, setLoading] = useState(true)
  const [statusModal, setModal] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    getTeam(() => {
      setLoading(false)
    })
  }, [])

  const getTagColor = (role) => {
    switch (role) {
      case RoleTagColor.OWNER.name:
        return RoleTagColor.OWNER.color

      case RoleTagColor.ADMINISTRATOR.name:
        return RoleTagColor.ADMINISTRATOR.color

      case RoleTagColor.USER.name:
        return RoleTagColor.USER.color

      default:
        return null
    }
  }

  const closeModal = () => setModal(false)
  const openModal = () => setModal(true)

  const onInvite = (values) => {
    setLoadingModal(true)
    postAuthInvite(pick(values, ['email', 'role']), (bol) => {
      setLoadingModal(false)
      if (bol) {
        getTeam(() => {
          setModal(false)
        })
      }
    })
  }

  let INVITED_USER = filter(team, (data) => data.status === undefined) || []

  return (
    <Fragment>
      {/**  Top  Header */}

      <TopContainer>
        <Title level={4} style={{ marginBottom: 0 }}>
          Team
        </Title>
        <Button type="primary" onClick={openModal}>
          Add Team
        </Button>
      </TopContainer>
      <Divider />

      {/**  User Table List */}

      <UserTable
        history={history}
        location={location}
        data={team}
        loading={loading}
        getTagColor={getTagColor}
      />

      {/**  Invite Table List */}

      {!isEmpty(INVITED_USER) && (
        <>
          <TopContainer style={{ marginTop: 100 }}>
            <Text strong style={{ fontSize: 15, opacity: 0.7, paddingLeft: 16 }}>
              Invited User
            </Text>
          </TopContainer>

          <InviteTable
            data={INVITED_USER}
            postAuthInvite={postAuthInvite}
            getTeam={getTeam}
            deleteTempAuth={deleteTempAuth}
          />
        </>
      )}

      {/**  Invite Modal */}

      <Modal
        visible={statusModal}
        onCancel={closeModal}
        title={'Invite new member'}
        okText={'Invite'}
        onOk={form.submit}
        confirmLoading={loadingModal}>
        <InviteForm form={form} onInvite={onInvite} role={auth.role} />
      </Modal>
    </Fragment>
  )
}

export default Team
