import React from 'react'
import { Tag, Image, Typography } from 'antd'
import { List } from 'antd/lib/form/Form'
import { getDate } from '../../../../utils/basic'
import { isEmpty } from 'lodash'

const { Text, Paragraph, Link } = Typography

const ProductCard = ({ name, site, date, status }) => {
  const getDateInfo = (d) => {
    let Time = new Date(d)
    let Month = monthName(Time.getMonth())
    let D = Time.getDate()
    let Hours = Time.getHours()
    let today = new Date().getDate()
    let Minutes = Time.getMinutes().toString()

    if (Minutes.length === 1) Minutes = `0${Minutes}`

    let to = ''

    if (D - today === 0) to = 'Today'
    else if (D - today === 1) to = 'Tomorrow'
    else to = `${D} ${Month}`

    return `${to}, ${Hours % 12 || 12}:${Minutes} ${Hours >= 12 ? 'PM' : 'AM'} `
  }

  const monthName = (index) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ]

    return monthNames[index]
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', opacity: `${status ? '1' : '0.5'}` }}>
      <div style={{ display: 'flex', flexFlow: 'column', padding: '0 12px' }}>
        <div>
          <>
            <Tag style={{ width: 'max-content', marginBottom: 8 }} color="green">
              {site}
            </Tag>
          </>
        </div>

        <Text strong style={{ opacity: 0.75 }}>
          {name}
        </Text>

        <Paragraph strong type="secondary">
          {isEmpty(date) ? 'Out of Stock' : `Sale: ${getDateInfo(date)} `}
        </Paragraph>
      </div>
    </div>
  )
}

export default React.memo(ProductCard)
