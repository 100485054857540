import { connect } from 'react-redux'
import Brand from '../../pages/brand'
import { getBrand, postBrand, patchBrand, deleteBrand } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { brand: state.brand }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postBrand: (data, CB) => dispatch(postBrand(data, CB)),
    patchBrand: (data, CB) => dispatch(patchBrand(data, CB)),
    getBrand: (CB) => dispatch(getBrand(CB)),
    deleteBrand: (data, CB) => dispatch(deleteBrand(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Brand)
