import { connect } from 'react-redux'
import TeamProfile from '../../pages/team/profile'
import { eachUser, postChangePassword, changeStatus, deleteAuth } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { eachUser: state.eachUser, auth: state.auth }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEachUser: (data, CB) => dispatch(eachUser(data, CB)),
    postChangePassword: (data, CB) => dispatch(postChangePassword(data, CB)),
    changeStatus: (data, CB) => dispatch(changeStatus(data, CB)),
    deleteAuth: (data, CB) => dispatch(deleteAuth(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamProfile)
