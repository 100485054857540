import React from 'react'
import { connect } from 'react-redux'
import EachStore from '../../pages/stores/each'
import qs from 'qs'
import {
  getEachStore,
  postInventory,
  getStore,
  patchInventory,
  getInventory,
  deleteInventory,
  postScrap,
  postScrapAllStore,
  getCategory,
  patchInventoryChangeCategory,
} from '../../actions/asyncAction'
import { omit, pick } from 'lodash'

const EachStoreContainer = (props) => {
  const { history, location, match } = props

  const getInventory = (CB) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    const query = qs.stringify({ store: match.params.id, ...params })
    props.getInventory(query, CB)
    history.push(`${location.pathname}?${query}`)
  }

  const onPagination = (page, CB) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })

    const query = qs.stringify({ store: match.params.id, page, ...omit(params, 'page') })
    props.getInventory(query, CB)
    history.push(`${location.pathname}?${query}`)
    document.documentElement.scrollTop = 0
  }

  const onSearch = (term, CB) => {
    let query = {}

    if (term) query = qs.stringify({ store: match.params.id, page: 1, term })
    else query = qs.stringify({ store: match.params.id, page: 1 })

    props.getInventory(query, CB)
    history.push(`${location.pathname}?${query}`)
    document.documentElement.scrollTop = 0
  }

  const onFilterApply = (data, CB) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })
    const t = { page: 1, ...data, store: match.params.id, ...pick(params, 'term') }
    const query = qs.stringify(t)
    props.getInventory(query, CB)
    history.push(`${location.pathname}?${query}`)
  }

  const onClearFilter = (CB) => {
    const query = qs.stringify({ store: match.params.id })
    props.getInventory(query, CB)
    history.push(`${location.pathname}?${query}`)
  }

  return (
    <EachStore
      {...props}
      onPagination={onPagination}
      getInventory={getInventory}
      onFilterApply={onFilterApply}
      onClearFilter={onClearFilter}
      onSearch={onSearch}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    eachStore: state.eachStore,
    store: state.store,
    inventory: state.inventory,
    category: state.category,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEachStore: (data, CB) => dispatch(getEachStore(data, CB)),
    postInventory: (data, CB) => dispatch(postInventory(data, CB)),
    getStore: (CB) => dispatch(getStore(CB)),
    getCategory: (CB) => dispatch(getCategory(CB)),
    patchInventory: (data, CB) => dispatch(patchInventory(data, CB)),
    patchInventoryChangeCategory: (data, CB) => dispatch(patchInventoryChangeCategory(data, CB)),
    getInventory: (query, CB) => dispatch(getInventory(query, CB)),
    deleteInventory: (data, CB) => dispatch(deleteInventory(data, CB)),
    postScrap: (data, CB) => dispatch(postScrap(data, CB)),
    postScrapAllStore: (data, CB) => dispatch(postScrapAllStore(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EachStoreContainer)
