import React from 'react'
import { map, capitalize } from 'lodash'
import { Button } from 'antd'

const ProductDetail = ({ data }) => {
  return (
    <ul style={{ margin: 0 }}>
      {map(data, (d) => (
        <li key={d._id} style={{ listStyle: 'inside' }}>
          <Button
            type="link"
            href={d.url}
            target="_blank"
            style={{ marginBottom: 0 }}>{`${capitalize(d?.color ?? 'No Color Data')} | ${
            d?.variantType ?? 'No Variant Data'
          }`}</Button>
        </li>
      ))}
    </ul>
  )
}

export default React.memo(ProductDetail)
