import React, { useState } from 'react'
import { Tabs } from 'antd'
import ProcessorForm from './form/processor'
import ScreenForm from './form/screen'
import RamForm from './form/ram'
import GraphicsCard from './form/graphics_card'
import HardDriveForm from './form/hard_drive'
import SecondHardDriveForm from './form/second_hard_drive'
import ReadingForm from './form/reading'
import ConnectivityForm from './form/connectivity'
import SoftwareForm from './form/software'
import GeneralForm from './form/general'

const { TabPane } = Tabs

const SPECS_TYPE = {
  PROCESSOR: 'processor',
  SCREEN: 'screen',
  RAM: 'ram',
  GRAPHICS_CARD: 'graphics_card',
  HARD_DRIVE: 'hard_drive',
  SECOND_HARD_DRIVE: 'second_hard_drive',
  READING: 'reading',
  CONNECTIVITY: 'connectivity',
  SOFTWARE: 'software',
  GENERAL: 'general',
}

const Specs = ({ onFinish, specs_data }) => {
  return (
    <Tabs type="card" tabPosition="left" onChange={(e) => {}}>
      <TabPane tab="Processor" key={SPECS_TYPE.PROCESSOR}>
        <ProcessorForm
          data={specs_data[SPECS_TYPE.PROCESSOR]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.PROCESSOR })}
        />
      </TabPane>
      <TabPane tab="Screen" key={SPECS_TYPE.SCREEN}>
        <ScreenForm
          data={specs_data[SPECS_TYPE.SCREEN]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.SCREEN })}
        />
      </TabPane>
      <TabPane tab="RAM" key={SPECS_TYPE.RAM}>
        <RamForm
          data={specs_data[SPECS_TYPE.RAM]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.RAM })}
        />
      </TabPane>
      <TabPane tab="Graphics Card" key={SPECS_TYPE.GRAPHICS_CARD}>
        <GraphicsCard
          data={specs_data[SPECS_TYPE.GRAPHICS_CARD]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.GRAPHICS_CARD })}
        />
      </TabPane>
      <TabPane tab="Hard Drive" key={SPECS_TYPE.HARD_DRIVE}>
        <HardDriveForm
          data={specs_data[SPECS_TYPE.HARD_DRIVE]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.HARD_DRIVE })}
        />
      </TabPane>
      <TabPane tab="Second Hard Drive" key={SPECS_TYPE.SECOND_HARD_DRIVE}>
        <SecondHardDriveForm
          data={specs_data[SPECS_TYPE.SECOND_HARD_DRIVE]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.SECOND_HARD_DRIVE })}
        />
      </TabPane>
      <TabPane tab="Reading" key={SPECS_TYPE.READING}>
        <ReadingForm
          data={specs_data[SPECS_TYPE.READING]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.READING })}
        />
      </TabPane>
      <TabPane tab="Connectivity" key={SPECS_TYPE.CONNECTIVITY}>
        <ConnectivityForm
          data={specs_data[SPECS_TYPE.CONNECTIVITY]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.CONNECTIVITY })}
        />
      </TabPane>
      <TabPane tab="Software" key={SPECS_TYPE.SOFTWARE}>
        <SoftwareForm
          data={specs_data[SPECS_TYPE.SOFTWARE]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.SOFTWARE })}
        />
      </TabPane>
      <TabPane tab="General" key={SPECS_TYPE.GENERAL}>
        <GeneralForm
          data={specs_data[SPECS_TYPE.GENERAL]}
          onFinish={(data) => onFinish({ ...data, specs_type: SPECS_TYPE.GENERAL })}
        />
      </TabPane>
    </Tabs>
  )
}

export default Specs
