import { connect } from 'react-redux'
import Team from '../../pages/team'
import { getTeam, postAuthInvite, deleteTempAuth } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { team: state.team, auth: state.auth }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTeam: (CB) => dispatch(getTeam(CB)),
    postAuthInvite: (data, CB) => dispatch(postAuthInvite(data, CB)),
    deleteTempAuth: (data, CB) => dispatch(deleteTempAuth(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)
