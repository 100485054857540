import React from 'react'
import filter from 'lodash/filter'
import { Table, Avatar, Tag } from 'antd'
import { UserOutlined, RightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const UserTable = ({ history, data, loading, getTagColor, location }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, data) => (
        <a style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
          <strong style={{ textTransform: 'capitalize', color: '#2d3436' }}>{value}</strong>
        </a>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      render: (value, data) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Tag
            color={getTagColor(value)}
            style={{
              fontSize: 12,
              fontWeight: 700,
              textTransform: 'uppercase',
              letterSpacing: 0.5,
            }}>
            {value}
          </Tag>

          {!data.status && (
            <Tag
              color={'#bfbfbf'}
              style={{
                fontSize: 12,
                fontWeight: 700,
                textTransform: 'uppercase',
                letterSpacing: 0.5,
              }}>
              blocked
            </Tag>
          )}
          <RightOutlined style={{ paddingLeft: 8 }} />
        </div>
      ),
    },
  ]

  return (
    <Table
      showHeader={false}
      rowKey={(record) => record._id}
      loading={loading}
      dataSource={filter(data, (d) => d.status !== undefined) || []}
      columns={columns}
      pagination={false}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            history.push(`${location.pathname}/profile/${record.email}`)
          },
        }
      }}
    />
  )
}

UserTable.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getTagColor: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default UserTable
