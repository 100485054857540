import React from 'react'
import { Form, Input, Select, InputNumber } from 'antd'
import Proptypes, { number } from 'prop-types'
import map from 'lodash/map'

const { Option } = Select

const InviteForm = ({ form, onFinish, category }) => {
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      name="inventory"
      form={form}
      onFinish={onFinish}>
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Title is required!',
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="spid"
        label="SPID"
        rules={[
          {
            required: true,
            message: 'SPID is required!',
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label="URL"
        rules={[
          {
            required: true,
            type: 'url',
            message: 'Please input URL!',
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="category"
        label="Category"
        rules={[
          {
            required: true,
            type: 'string',
            message: 'Please select category!',
          },
        ]}>
        <Select placeholder="Select a category" allowClear>
          {map(category, (data) => (
            <Option value={data._id}>{data.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="mrp"
        label="MRP"
        rules={[
          {
            type: 'number',
            message: 'Please input valid number!',
          },
        ]}>
        <InputNumber style={{ minWidth: 200 }} min={0} />
      </Form.Item>

      <Form.Item
        name="price"
        label="Price"
        rules={[
          {
            type: 'number',
            message: 'Please input valid number!',
          },
        ]}>
        <InputNumber style={{ minWidth: 200 }} min={0} />
      </Form.Item>

      <Form.Item
        name="rating"
        label="Rating"
        rules={[
          {
            type: 'number',
            message: 'Please input valid number!',
          },
        ]}>
        <InputNumber style={{ minWidth: 200 }} min={0} />
      </Form.Item>
      <Form.Item
        name="rating_count"
        label="Rate Count"
        rules={[
          {
            type: 'number',
            message: 'Please input valid number!',
          },
        ]}>
        <InputNumber style={{ minWidth: 200 }} min={0} />
      </Form.Item>

      <Form.Item
        name="pos"
        label="Position"
        rules={[
          {
            type: 'number',
            message: 'Please input valid number!',
          },
        ]}>
        <InputNumber style={{ minWidth: 200 }} min={0} />
      </Form.Item>
    </Form>
  )
}

// InviteForm.propTypes = {
//   from: Proptypes.object.isRequired,
//   onInvite: Proptypes.func.isRequired,
//   role: Proptypes.string.isRequired,
// };

export default InviteForm
