import React, { useEffect, useState } from 'react'
import {
  PageHeader,
  Button,
  List,
  Card,
  Modal,
  Form,
  Typography,
  Popconfirm,
  Input,
  message,
  Tag,
  Drawer,
  Select,
} from 'antd'
import {
  PlusOutlined,
  DatabaseOutlined,
  FilterOutlined,
  CodeSandboxCircleFilled,
} from '@ant-design/icons'
import moment from 'moment'
import AddInventoryForm from '../../../component/forms/inventory/add'
import FilterForm from './filterForm'
import { getDate, getTitleFromURL, displayPrice } from '../../../utils/basic'
import { pick, lowerCase, isEmpty, filter, capitalize, pickBy, isNumber, omit, map } from 'lodash'
import qs from 'qs'

const { Search } = Input
const { Option } = Select
const { Text, Paragraph } = Typography
const OPERATION = { ADD: 'add', EDIT: 'edit' }

const EachStore = ({
  inventory,
  history,
  location,
  getInventory,
  postInventory,
  patchInventory,
  deleteInventory,
  postScrap,
  postScrapAllStore,
  getCategory,
  match,
  getStore,
  store,
  onPagination,
  category,
  onFilterApply,
  onClearFilter,
  onSearch,
  patchInventoryChangeCategory,
}) => {
  const [loading, setLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState()
  const [operation, setOperation] = useState()
  const [selectedStore, setSelectedStore] = useState()
  const [selectedID, setSelectedID] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [allparams, setAllparams] = useState()
  const [form] = Form.useForm()

  const NAME = capitalize(match.params.name).replace(/-/g, ' ')

  useEffect(() => {
    setSelectedStore(match.params.id)
    getInventory((bol) => {
      getCategory((bol) => {
        setLoading(false)
      })
    })
  }, [])

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })

    setAllparams(params)

    form.setFieldsValue({
      category: params.category,
      min_price: params.min_price,
      max_price: params.max_price,
      no_store: params.no_store && params.no_store === 'true' ? true : false,
    })

    setSearchTerm(params.term)
  }, [location.search])

  const onFinish = (values) => {
    switch (operation) {
      case OPERATION.ADD:
        const data = {
          store: selectedStore._id,
          ...pickBy(values, (e) => !isEmpty(e) || isNumber(e)),
        }

        postInventory(data, (bol) => {
          if (bol) {
            getInventory(() => {})
            setModalVisible(false)
            form.resetFields()
          }
        })
        break
      case OPERATION.EDIT:
        const data1 = {
          // _id: selectedID,
          store: selectedStore,
          ...pickBy(values, (e) => !isEmpty(e) || isNumber(e)),
        }
        patchInventory(data1, (bol) => {
          if (bol) {
            getInventory(() => {})
            setModalVisible(false)
            form.resetFields()
          }
        })
        break
      default:
        break
    }
  }

  const openModal = (operation) => {
    setModalVisible(true)
    setOperation(operation)
  }

  const openDrawer = () => {
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onDelete = (_id) => {
    deleteInventory({ _id }, (bol) => {
      if (bol) getInventory(() => {})
    })
  }

  const renderDate = (data) => {
    const { date, month, year } = getDate(data)
    return `${date} ${month}, ${year}`
  }

  // const getFilteredData = (term, data) => {
  //   if (isEmpty(term)) return data;
  //   else
  //     return filter(data, (d) => lowerCase(d.name).includes(lowerCase(term)));
  // };

  const onPageChange = (page) => {
    setLoading(true)
    onPagination(page, () => {
      setLoading(false)
    })
  }

  const onFilter = (value) => {
    let data = { no_store: false }

    if (!isEmpty(value.category)) data['category'] = value.category
    if (value.min_price) data['min_price'] = value.min_price
    if (value.max_price) data['max_price'] = value.max_price
    if (value.no_store) data['no_store'] = value.no_store

    if (!isEmpty(data)) {
      setLoading(true)

      onFilterApply(data, () => {
        setLoading(false)
      })
    }
  }

  const onCategoryChange = (category, id) => {
    setLoading(true)
    patchInventoryChangeCategory({ category, id }, () => {
      getInventory(() => {
        setLoading(false)
      })
    })
  }

  console.log(selectedStore)

  return (
    <>
      <PageHeader
        title={inventory.data && inventory.data[0] && inventory.data[0].store.name}
        subTitle={`(${isEmpty(inventory) ? 0 : inventory.length})`}
        onBack={() => history.goBack()}
        extra={[
          <div style={{ display: 'flex' }} key={1}>
            <Search
              style={{ marginRight: 16 }}
              key={1}
              placeholder={'Search on title'}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onSearch={() => {
                setLoading(true)
                onSearch(searchTerm, () => {
                  setLoading(false)
                })
              }}
            />

            <Button
              disabled={inventory.data ? false : true}
              key={2}
              style={{ marginRight: 16 }}
              onClick={() => {
                postScrapAllStore({ storeID: selectedStore }, (bol) => {
                  if (bol) message.success('All Store added to update', 1)
                })
              }}>
              Update all
            </Button>

            <Button
              key={3}
              type="primary"
              style={{ marginRight: 16 }}
              onClick={() => openModal(OPERATION.ADD)}>
              <PlusOutlined /> Add Inventory
            </Button>

            <Button key={4} onClick={openDrawer}>
              <FilterOutlined />
            </Button>
          </div>,
        ]}
        style={{ borderBottom: '1px solid #eee' }}
      />

      <Card bordered={false}>
        <List
          itemLayout="horizontal"
          dataSource={inventory.data || []}
          loading={loading}
          pagination={{
            total: inventory.length,
            hideOnSinglePage: true,
            showSizeChanger: false,
            onChange: onPageChange,
            current: allparams && allparams.page ? Number(allparams.page) : 1,
          }}
          renderItem={(item) => {
            let tempDay = new Date(item.updatedAt)

            let date = tempDay.getDate()
            let month = tempDay.getMonth()
            let year = tempDay.getFullYear()
            let hour = tempDay.getHours()
            let minute = tempDay.getMinutes()
            let second = tempDay.getSeconds()

            let d = moment([year, month, date, hour, minute, second]).toNow(true)

            return (
              <List.Item
                actions={[
                  <Select
                    key={0}
                    // showSearch
                    style={{ minWidth: 130 }}
                    optionFilterProp="children"
                    value={item.category._id}
                    onChange={(e) => {
                      onCategoryChange(e, item._id)
                    }}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {map(category, (cat) => (
                      <Option key={cat._id} value={cat._id}>
                        {cat.name}
                      </Option>
                    ))}
                  </Select>,
                  <Button
                    type="link"
                    key={1}
                    onClick={() => {
                      setSelectedID(item._id)
                      openModal(OPERATION.EDIT)
                      form.setFieldsValue({
                        ...omit(item, 'category'),
                        category: item.category._id,
                      })
                    }}>
                    Edit
                  </Button>,
                  <Button
                    type="link"
                    key={2}
                    onClick={() => {
                      postScrap([{ inventoryID: item._id, url: item.url }], (bol) => {
                        if (bol) message.success('Added to Update', 1)
                      })
                    }}>
                    Update
                  </Button>,

                  <Popconfirm
                    key={3}
                    placement="left"
                    title="Sure to delete?"
                    onConfirm={() => onDelete(item._id)}>
                    <Button type="link" danger>
                      Delete
                    </Button>
                  </Popconfirm>,
                ]}>
                <List.Item.Meta
                  title={
                    <Text strong copyable={{ text: item._id }}>
                      {/* <Tag>{item.category.name}</Tag> */}
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener nofollow"
                        style={{
                          color: item.product ? '#6ab04c' : '#e74c3c',
                        }}>
                        {item.title}
                      </a>
                    </Text>
                  }
                  description={`Last updated : ${d} ago`}
                />
                <div>
                  <Paragraph
                    style={{
                      fontSize: 16,
                      marginBottom: 0,
                      display: 'flex',
                      paddingLeft: 32,
                    }}>
                    {/* <Text strong>Rs.</Text> */}
                    {/* <Text delete style={{ paddingRight: 4, fontSize: 13 }}>
                      {item.mrp ? item.mrp : null}
                    </Text> */}
                    <Text>{item.price ? displayPrice(item.price) : 0}</Text>
                  </Paragraph>
                </div>
              </List.Item>
            )
          }}
        />
      </Card>

      <Drawer
        title="Filters"
        placement="right"
        width={400}
        destroyOnClose
        closable={false}
        onClose={closeDrawer}
        visible={drawerVisible}
        footer={
          <div
            style={{
              textAlign: 'center',
              padding: '24px',
            }}>
            <Button
              key={1}
              onClick={() => {
                form.submit()
                closeDrawer()
              }}
              type="primary"
              style={{ marginRight: 16 }}>
              Apply Filter
            </Button>
            <Button
              key={2}
              onClick={() => {
                setLoading(true)
                onClearFilter(() => {
                  closeDrawer()
                  form.resetFields()
                  setLoading(false)
                })
              }}>
              Clear Filter
            </Button>
          </div>
        }
        footerStyle={{ height: 100 }}>
        <FilterForm form={form} onFinish={onFilter} category={category} allparams={allparams} />
      </Drawer>

      <Modal
        destroyOnClose
        title={operation === OPERATION.EDIT ? 'Edit Inventory' : 'Add Inventory'}
        okText={operation === OPERATION.EDIT ? 'Edit' : 'Add'}
        visible={modalVisible}
        centered
        onCancel={() => {
          setModalVisible(false)
          setSelectedID()
          form.resetFields()
        }}
        onOk={form.submit}>
        <AddInventoryForm form={form} onFinish={onFinish} category={category} />
      </Modal>
    </>
  )
}

export default EachStore
