import ActionTypes from '../constants/actionTypes'

// Sale Product
export const saleProduct = (data) => ({
  type: ActionTypes.SALE_PRRODUCT,
  payload: data,
})

//  Each Sale Product
export const eachSaleProduct = (data) => ({
  type: ActionTypes.EACH_SALE_PRODUCT,
  payload: data,
})

// Inventory
export const inventory = (data) => ({
  type: ActionTypes.INVENTORY,
  payload: data,
})

// Product
export const product = (data) => ({
  type: ActionTypes.PRODUCT,
  payload: data,
})

// Brand
export const brand = (data) => ({
  type: ActionTypes.BRAND,
  payload: data,
})

// Each Product
export const eachProduct = (data) => ({
  type: ActionTypes.EACH_PRODUCT,
  payload: data,
})

// Store
export const store = (data) => ({
  type: ActionTypes.STORE,
  payload: data,
})

// Category
export const category = (data) => ({
  type: ActionTypes.CATEGORY,
  payload: data,
})

//  Each Category
export const eachCategory = (data) => ({
  type: ActionTypes.EACH_CATEGORY,
  payload: data,
})

// Scrap
export const scrap = (data) => ({
  type: ActionTypes.SCRAP,
  payload: data,
})

//  Each Store
export const eachStore = (data) => ({
  type: ActionTypes.EACH_STORE,
  payload: data,
})

// Team
export const team = (data) => ({
  type: ActionTypes.TEAM,
  payload: data,
})

// Auth

export const postLogin = (data) => ({
  type: ActionTypes.LOGIN,
  payload: data,
})

export const logout = () => {
  localStorage.removeItem('token')
  window.location.reload()
  return {
    type: ActionTypes.LOGOUT,
  }
}
export const authRefresh = (data) => ({
  type: ActionTypes.REFRESH,
  payload: data,
})

export const eachUser = (data) => ({
  type: ActionTypes.EACH_USER,
  payload: data,
})
