import React from 'react'
import { Typography, message, Row, Col } from 'antd'
import { FormContainer, BrandLogo } from './login.styled'
import LoginForm from '../../../component/forms/auth/login'
import Logo from '../../../static/logo.png'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'

const { Title } = Typography

const Login = ({ history, postLogin }) => {
  const onLogin = (values) => {
    postLogin(pick(values, ['email', 'password']))
      .then((resp) => history.push('/dashboard'))
      .catch((err) => message.error('Authentication Failed'))
  }

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Row style={{ height: '100vh' }}>
      <Col span={12}>
        <BrandLogo>
          <img style={{ width: '40%' }} src={Logo} />
        </BrandLogo>
      </Col>
      <Col span={12} style={{ background: '#f0f0f0' }}>
        <FormContainer>
          <Title level={3} style={{ marginBottom: 24 }}>
            Member Login
          </Title>
          <LoginForm onLogin={onLogin} onError={onError} />
        </FormContainer>
      </Col>
    </Row>
  )
}

Login.propTypes = {
  postLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default Login
