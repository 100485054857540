import { connect } from 'react-redux'
import Stores from '../../pages/stores'
import { getStore, postStore, patchStore, deleteStore } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { store: state.store }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postStore: (data, CB) => dispatch(postStore(data, CB)),
    patchStore: (data, CB) => dispatch(patchStore(data, CB)),
    getStore: (CB) => dispatch(getStore(CB)),
    deleteStore: (data, CB) => dispatch(deleteStore(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
