import React from 'react'
import { Form, Input, Button, Checkbox } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import Proptypes from 'prop-types'

const LoginForm = ({ onLogin, onError }) => {
  return (
    <Form
      style={{ minWidth: 375 }}
      name="login-form"
      initialValues={{
        email: '',
        password: '',
        remember: true,
      }}
      onFinish={onLogin}
      onFinishFailed={onError}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}>
        <Input
          size="large"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="email"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}>
        <Input.Password
          size="large"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 8 }}>
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} size="large">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

// LoginForm.propTypes = Proptypes.exact({
//   onLogin: Proptypes.func.isRequired,
//   onError: Proptypes.func.isRequired,
// });

export default React.memo(LoginForm)
