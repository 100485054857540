import ActionTypes from '../constants/actionTypes'
import initialState from '../stores/initialState'

export default (state = initialState.product, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT:
      const { payload } = action
      return payload
    default:
      return state
  }
}
