import React from 'react'
import { Form, Input, Button } from 'antd'
import { map } from 'lodash'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
}

const FIELD = [
  { label: 'IPS Panel', name: 'ips_panel', type: 'string' },
  { label: 'Diagonal', name: 'diagonal', type: 'string' },
  { label: 'Analysis', name: 'analysis', type: 'string' },
  { label: 'Possibilities', name: 'possibilities', type: 'string' },
]

const Screen = ({ data, onFinish }) => {
  return (
    <Form
      name="screen-form"
      {...layout}
      style={{ minWidth: 375, padding: '24px 0' }}
      initialValues={{ ...data }}
      onFinish={onFinish}>
      {map(FIELD, (field, j) => {
        return (
          <Form.Item name={field.name} label={field.label} key={j}>
            <Input />
          </Form.Item>
        )
      })}

      <Form.Item {...tailLayout} key={'submit'}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Screen
