import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Layout, Menu, Breadcrumb } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import HeaderContiner from '../containers/header.container'
import { getDate } from '../utils/basic'
import {
  PieChartOutlined,
  TeamOutlined,
  DatabaseOutlined,
  AppstoreOutlined,
  DingtalkOutlined,
  RadarChartOutlined,
  GoldOutlined,
} from '@ant-design/icons'
// import NAV from "../data/nav.json";
// import Can from "../components/resuable/can";

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

const NAV = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: <PieChartOutlined />,
    submenu: [],
    access: ['owner', 'administrator', 'user'],
  },
  {
    name: 'ChromeXT',
    icon: <DatabaseOutlined />,
    submenu: [
      {
        menu1: 'Sale Products',
        url: '/chromext/sale',
        access: ['owner', 'administrator', 'helper'],
      },
    ],
  },

  {
    name: 'Product',
    url: '/product',
    icon: <DatabaseOutlined />,
    submenu: [],
    access: ['owner', 'administrator', 'user'],
  },
  // {
  //   name: "Inventory",
  //   url: "/inventory",
  //   icon: <DatabaseOutlined />,
  //   submenu: [],
  //   access: ["owner", "administrator", "user"],
  // },
  {
    name: 'Stores',
    url: '/stores',
    icon: <AppstoreOutlined />,
    submenu: [],
    access: ['owner', 'administrator', 'user'],
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: <GoldOutlined />,
    submenu: [],
    access: ['owner', 'administrator', 'user'],
  },

  {
    name: 'Brand',
    url: '/brand',
    icon: <DingtalkOutlined />,
    submenu: [],
    access: ['owner', 'administrator', 'user'],
  },

  {
    name: 'Scrap List',
    url: '/scrap',
    icon: <RadarChartOutlined />,
    submenu: [],
    access: ['owner', 'administrator', 'user'],
  },
  {
    name: 'Team',
    url: '/team',
    icon: <TeamOutlined />,
    submenu: [],
    access: ['owner', 'administrator'],
  },
]

const Logo = styled.div`
  text-align: center;
  margin: 16px 0;

  height: 30px;
  > p {
    margin: 0;
  }
  > p > b {
    color: #a3cb38;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
  }
  > p > span {
    padding: 0 6px;
    font-size: 14px;
    color: #fff;
  }
`

const Middle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Today = styled.span`
  padding: 0 8px;
  font-weight: bold;
  font-size: 1rem;
  opacity: 0.8;
`

class LayoutCover extends Component {
  state = {
    collapsed: false,
    panelName: 'Tweksen',
    // panelVersion: "v.1",
    // activekey: ["0"],
    footer: 'Tweksen Dashboard © 2021 Pvt Ltd',
  }

  onCollapse = (collapsed) => this.setState({ collapsed })

  onMenuClick = (event, data) => {}

  _renderDate = (data) => {
    const { day, date, month } = getDate(data)
    return `${day}, ${date} ${month}`
  }

  renderBreadcrumb = () => {
    const { pathname } = this.props.location
    const PATH = _.filter(pathname.split('/'), (PATH) => PATH !== '')
    return (
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        {_.map(PATH || [], (p, i) => (
          <Breadcrumb.Item key={i}>{p}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    )
  }

  render() {
    const { children, auth } = this.props

    const { collapsed, panelName, panelVersion, footer } = this.state
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} theme="dark">
          <Logo>
            {!collapsed && (
              <p>
                <b>{panelName}</b> <span>{panelVersion}</span>
              </p>
            )}
          </Logo>
          <Menu
            theme="dark"
            mode="inline"
            // selectedKeys={activekey}
            defaultSelectedKeys={['0']}>
            {_.map(NAV, (data, index) => {
              if (data.submenu.length) {
                let fil = _.filter(data.submenu, (sub, i) => sub.access.includes(auth.role))

                if (fil.length)
                  return (
                    <SubMenu
                      key={`sub${index + 1}`}
                      title={
                        <span>
                          {data.icon}
                          <span>{data.name}</span>
                        </span>
                      }>
                      {_.map(data.submenu, (data1, i) => {
                        if (data1.access.includes(auth.role))
                          return (
                            <Menu.Item key={`${index}-${i}`}>
                              <Link to={data1.url}>{data1.menu1}</Link>
                            </Menu.Item>
                          )
                      })}
                    </SubMenu>
                  )
              } else {
                if (data.access.includes(auth.role))
                  return (
                    <Menu.Item key={index} onClick={(event) => this.onMenuClick(event, data)}>
                      <Link to={data.url}>
                        {data.icon}
                        <span>{data.name}</span>
                      </Link>
                    </Menu.Item>
                  )
              }
            })}
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ display: 'flex', background: '#fff', padding: 0 }}>
            <HeaderContiner></HeaderContiner>
          </Header>
          <Content style={{ margin: '0 24px' }}>
            <Middle>
              {this.renderBreadcrumb()}
              <Today>{this._renderDate(new Date())}</Today>
            </Middle>
            <div
              style={{
                padding: 24,
                background: '#fff',
                minHeight: 360,
                borderRadius: 4,
              }}>
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>{footer}</Footer>
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(LayoutCover)
