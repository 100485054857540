import React, { useEffect, useState } from 'react'
import { PageHeader, Button, Divider } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ListTable from './component/listTable'

const Sale = ({
  saleProduct,
  history,
  location,
  getSaleProduct,
  deleteSaleProduct,
  patchSaleChangeStatus,
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getSaleProduct((bol) => {
      setLoading(false)
    })
  }, [])

  const onEdit = (record) => {
    history.push(`${location.pathname}/edit/${record._id}`)
  }

  const onDelete = (record) => {
    setLoading(true)
    deleteSaleProduct({ id: record._id }, (bol) => {
      if (bol) {
        getSaleProduct(() => {
          setLoading(false)
        })
      } else setLoading(false)
    })
  }

  const onChangeStatus = (record) => {
    setLoading(true)
    patchSaleChangeStatus({ id: record._id, status: !record.status }, (bol) => {
      if (bol) {
        getSaleProduct(() => {
          setLoading(false)
        })
      } else setLoading(false)
    })
  }

  return (
    <>
      <PageHeader
        title="Flash Sale Products"
        subTitle={`(${saleProduct?.length ?? 0})`}
        extra={[
          <Button
            style={{ marginRight: 16 }}
            key={3}
            type="primary"
            onClick={() => history.push(`${location.pathname}/new`)}>
            <PlusOutlined /> Add Product
          </Button>,
        ]}
      />
      <Divider style={{ marginTop: 12 }} />

      <ListTable
        data={saleProduct}
        totalDataLength={saleProduct.length}
        onChangeStatus={onChangeStatus}
        loading={loading}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  )
}

export default Sale
