import React, { useState } from 'react'
import { List, Button, Popconfirm, Form, Modal, Typography } from 'antd'
import AddStoreForm from './form/addStore'
import { getDate, getTitleFromURL } from '../../utils/basic'

const { Text, Paragraph } = Typography

const OPERATION = { ADD: 'add', REMOVE: 'remove' }

const Index = ({ onFinish, data, setModalVisible, modalVisible }) => {
  const [form] = Form.useForm()

  const renderDate = (data) => {
    const { date, month, year } = getDate(data)
    return `${date} ${month}, ${year}`
  }

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data || []}
        // pagination={true}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Popconfirm
                key={2}
                placement="left"
                title="Sure to delete?"
                onConfirm={() => {
                  onFinish({ operation: OPERATION.REMOVE, store: item._id })
                }}>
                <Button type="link" danger>
                  Delete
                </Button>
              </Popconfirm>,
            ]}>
            <List.Item.Meta
              avatar={<Text strong>{item.store.name}</Text>}
              title={
                <Text strong>
                  <a href={item.url} target="_blank" rel="noopener nofollow">
                    {item.title}
                  </a>
                </Text>
              }
              description={`Updated : ${renderDate(item.updatedAt)}`}
            />
            <div>
              <Paragraph
                style={{
                  fontSize: 16,
                  marginBottom: 0,
                  display: 'flex',
                  paddingLeft: 32,
                }}>
                <Text strong>Rs.</Text>
                <Text delete style={{ paddingRight: 4 }}>
                  {item.mrp ? item.mrp : null}
                </Text>
                <Text strong>{item.price ? item.price : 0}</Text>
              </Paragraph>
            </div>
          </List.Item>
        )}
      />

      <Button type="primary" style={{ marginTop: 24 }} onClick={() => setModalVisible(true)}>
        Add Store
      </Button>

      <Modal
        destroyOnClose
        title={'Add Store'}
        okText={'Add'}
        visible={modalVisible}
        centered
        onCancel={() => {
          setModalVisible(false)
          form.resetFields()
        }}
        onOk={form.submit}>
        <AddStoreForm
          form={form}
          onFinish={(data) => onFinish({ operation: OPERATION.ADD, ...data })}
        />
      </Modal>
    </>
  )
}

export default Index
