import React from 'react'
import { Form, Input } from 'antd'

const AddStoreForm = ({ form, onFinish }) => {
  return (
    <Form style={{ minWidth: 375 }} name="store-form" form={form} onFinish={onFinish}>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name!',
          },
        ]}>
        <Input />
      </Form.Item>
    </Form>
  )
}

export default AddStoreForm
