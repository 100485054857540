import { makeRequest } from '../constants/request'
import * as syncActions from './syncAction'
import AppConstants from '../constants/appConstants'
import { message } from 'antd'

// Flash Sale Product

export const getSaleProduct = (CB) => (dispatch) =>
  makeRequest('get', `/api/panel/sale`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.saleProduct(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const getSaleProductbyID = (id, CB) => (dispatch) =>
  makeRequest('get', `/api/panel/sale/${id}`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.eachSaleProduct(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postSaleProduct = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/sale`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchSaleProduct = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/sale`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchSaleChangeStatus = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/sale/status`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const addVariantToProduct = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/sale/variant/add`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const editVariantToProduct = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/sale/variant/edit`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const removeVariantFromProduct = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/sale/variant/remove`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const deleteSaleProduct = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/sale`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Scrap
export const getScrap = (CB) => (dispatch) =>
  makeRequest('get', `/api/panel/scrap`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.scrap(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postScrap = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/scrap`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postScrapAllStore = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/scrap/allstore`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Brand
export const getBrand = (CB) => (dispatch) =>
  makeRequest('get', `/api/panel/brand`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.brand(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postBrand = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/brand`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchBrand = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/brand`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const deleteBrand = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/brand`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Product
export const getProduct = (query, CB) => (dispatch) =>
  makeRequest(
    'get',
    `/api/panel/product?${query}`,
    {},
    { Authorization: localStorage.getItem('token') }
  )
    .then((resp) => {
      CB(true)
      dispatch(syncActions.product(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const getProductbyID = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/product/each`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true, resp.data)
      dispatch(syncActions.eachProduct(resp.data))
    })
    .catch((err) => {
      CB(false, {})
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postProduct = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/product`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchProduct = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/product`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true, resp.data)
    })
    .catch((err) => {
      CB(false, {})
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const deleteProduct = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/product`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Inventory
export const getInventory = (query, CB) => (dispatch) =>
  makeRequest(
    'get',
    `/api/panel/inventory?${query}`,
    {},
    { Authorization: localStorage.getItem('token') }
  )
    .then((resp) => {
      CB(true)
      dispatch(syncActions.inventory(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postInventory = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/inventory`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchInventory = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/inventory`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchInventoryChangeCategory = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/inventory/changecategory`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const deleteInventory = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/inventory`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Category

export const getCategory = (CB) => (dispatch) =>
  makeRequest('get', `/api/panel/category`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.category(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postCategory = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/category`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchCategory = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/category`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const deleteCategory = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/category`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Store

export const getStore = (CB) => (dispatch) =>
  makeRequest('get', `/api/panel/store`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.store(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const postStore = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/store`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const getEachStore = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/store/each`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.eachStore(resp.data))
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const patchStore = (data, CB) => (dispatch) =>
  makeRequest('patch', `/api/panel/store`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

export const deleteStore = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/store`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      CB(false)
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
    })

// Team
export const getTeam = (CB) => (dispatch) =>
  makeRequest('get', `/api/panel/auth`, {}, { Authorization: localStorage.getItem('token') })
    .then((resp) => {
      CB(true)
      dispatch(syncActions.team(resp.data))
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else if (err.response && err.response.data && err.response.data.error)
        message.error(err.response.data.error, 2)
      else message.error('error in the server', 2)
      CB(false)
    })

// Auth

export const changeStatus = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/status`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      // dispatch(syncActions.eachUser(resp.data));
      CB(true)
    })
    .catch((err) => {
      message.error('error in the server', 2)
      CB(false, { err })
    })

export const deleteAuth = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/auth`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      message.error('error in the server', 2)
      CB(false, { err })
    })

export const deleteTempAuth = (data, CB) => (dispatch) =>
  makeRequest('delete', `/api/panel/auth/temp`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      message.error('error in the server', 2)
      CB(false, { err })
    })

export const eachUser = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/each`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      dispatch(syncActions.eachUser(resp.data))
      CB(true)
    })
    .catch((err) => {
      CB(false, { err })
      if (err.response && err.response.status === 401) {
        dispatch(syncActions.logout())
      } else {
        message.error('error in the server', 2)
      }
    })

export const postChangePassword = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/change-password`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      localStorage.removeItem('token')
      CB(true)
    })
    .catch((err) => {
      message.error('error in the server', 2)
      CB(false, { err })
    })

export const postSignupVerify = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/signup/verify`, data)
    .then((resp) => {
      const { email, role, token } = resp.data
      CB(true, { email, role, token })
    })
    .catch((err) => {
      message.error('error in the server', 2)
      CB(false, { err })
    })

export const postAuthInvite = (data, CB) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/invite`, data, {
    Authorization: localStorage.getItem('token'),
  })
    .then((resp) => {
      CB(true)
    })
    .catch((err) => {
      message.error('error in the server', 2)
      CB(false)
    })

export const postSignup = (data, token) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/signup`, data, {
    Authorization: token,
  }).then((resp) => {
    localStorage.setItem('token', resp.data.token)
    dispatch(syncActions.postLogin(resp.data))
  })

export const postLogin = (data) => (dispatch) =>
  makeRequest('post', `/api/panel/auth/login`, data).then((resp) => {
    localStorage.setItem('token', resp.data.token)
    dispatch(syncActions.postLogin(resp.data))
  })
