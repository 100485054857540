import { connect } from 'react-redux'
import Dashboard from '../pages/dashboard'
import {} from '../actions/asyncAction'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
