import { connect } from 'react-redux'
import Signup from '../../pages/auth/signup'
import { postSignupVerify, postSignup } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    postSignupVerify: (data, CB) => dispatch(postSignupVerify(data, CB)),
    postSignup: (data, token) => dispatch(postSignup(data, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
