import React from 'react'
import { Switch, Route } from 'react-router-dom'

import dashboard from '../containers/dashboard.container'
import chromext_sale from '../containers/chromext.container/sale'
import chromext_sale_new from '../containers/chromext.container/sale/new'
import chromext_sale_edit from '../containers/chromext.container/sale/edit'
import product from '../containers/product.container'
import product_new from '../containers/product.container/new'
import product_edit from '../containers/product.container/edit'
import categories from '../containers/categories.container'
import stores from '../containers/stores.container'
import stores_each from '../containers/stores.container/each'
import brand from '../containers/brand.container'
import scrap from '../containers/scrap.container'
import team from '../containers/team.container'
import team_profile from '../containers/team.container/profile'

const AllRoute = () => {
  return (
    <Switch>
      <Route exact strict path="/dashboard" component={dashboard} />
      <Route exact strict path="/chromext/sale" component={chromext_sale} />
      <Route exact strict path="/chromext/sale/new" component={chromext_sale_new} />
      <Route exact strict path="/chromext/sale/edit/:id" component={chromext_sale_edit} />
      <Route exact path="/product" component={product} />
      <Route exact path="/product/new" component={product_new} />
      <Route exact path="/product/edit/:id" component={product_edit} />
      <Route exact strict path="/categories" component={categories} />
      <Route exact strict path="/stores" component={stores} />
      <Route exact strict path="/stores/:id" component={stores_each} />
      <Route exact strict path="/brand" component={brand} />
      <Route exact strict path="/scrap" component={scrap} />
      <Route exact strict path="/team" component={team} />
      <Route exact strict path="/team/profile/:email" component={team_profile} />
    </Switch>
  )
}

export default AllRoute
