import styled from 'styled-components'

const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const BrandLogo = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;

  > p {
    border: 1px solid #aaa;
    padding: 16px 24px;
    font-size: 42px;
    font-weight: bold;
    color: #aaa;
  }
`

export { FormContainer, BrandLogo }
