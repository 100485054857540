import React, { useEffect, useState } from 'react'
import { PageHeader, Typography, Divider, Tabs, Form, Spin } from 'antd'
import BasicForm from './form/basic'
import SpecificationForm from './form/specification'
import ImagesUpload from './form/images'
import { pickBy, isEmpty, isString } from 'lodash'

const { TabPane } = Tabs
const { Title, Text } = Typography

const TAB = {
  BASIC: 'basic',
  SPECIFICATION: 'specification',
  STORES: 'stores',
  IMAGES: 'images',
}

const Index = ({ history, postProduct, getBrand, brand, category, getCategory }) => {
  const [currentTab, setTab] = useState(TAB.BASIC)
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()

  useEffect(() => {
    Promise.all([getBrand((bol) => {}), getCategory((bol) => {})])
      .then(() => setLoading(false))
      .catch((err) => console.log(err))
  }, [])

  const onFinish = (values) => {
    const data = {
      type: currentTab,
      ...pickBy(values, (e) => !isEmpty(e)),
    }
    postProduct(data, (bol) => {
      if (bol) history.push('/product')
    })
  }

  return (
    <Spin spinning={loading}>
      <PageHeader title="Add New Product" onBack={() => history.goBack()} />
      <Divider style={{ marginTop: 12 }} />

      <Tabs type="card" onChange={(e) => setTab(e)} defaultActiveKey={currentTab}>
        <TabPane tab="Basic" key={TAB.BASIC}>
          <BasicForm
            form={form}
            type="basic"
            onFinish={onFinish}
            brand={brand}
            category={category}
          />
        </TabPane>
        <TabPane tab="Specification" disabled key={TAB.SPECIFICATION}>
          <SpecificationForm />
        </TabPane>
        <TabPane tab="Stores" disabled key={TAB.STORES}></TabPane>
        <TabPane tab="Images" disabled key={TAB.IMAGES}>
          <ImagesUpload />
        </TabPane>
        {/* <TabPane tab="SEO" key="5"></TabPane> */}
      </Tabs>
    </Spin>
  )
}

export default Index
