import { connect } from 'react-redux'
import Categories from '../../pages/categories'
import { getCategory, postCategory, patchCategory, deleteCategory } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { category: state.category }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postCategory: (data, CB) => dispatch(postCategory(data, CB)),
    patchCategory: (data, CB) => dispatch(patchCategory(data, CB)),
    getCategory: (CB) => dispatch(getCategory(CB)),
    deleteCategory: (data, CB) => dispatch(deleteCategory(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
