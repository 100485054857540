import { connect } from 'react-redux'
import Login from '../../pages/auth/login'
import { postLogin } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { auth: state.auth }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postLogin: (data) => dispatch(postLogin(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
