import React from 'react'
import { Provider } from 'react-redux'
import stores from './stores'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import routes from './routes'

// Routes
import Login from './containers/auth.container/login'
import Signup from './containers/auth.container/signup'
import ForgotPassword from './containers/auth.container/forgotPassword'
import requireAuth from './hoc/requireAuth'

const App = () => {
  return (
    <Provider store={stores}>
      <BrowserRouter>
        <Switch>
          <Route exact strict path="/auth/login" component={Login} />
          <Route exact strict path="/auth/signup/:uuid" component={Signup} />
          <Route exact strict path="/auth/forgot-password" component={ForgotPassword} />
          <Route component={requireAuth(routes)} />
        </Switch>

        {
          // Route to handle 404
        }
      </BrowserRouter>
    </Provider>
  )
}

export default App
