import { combineReducers } from 'redux'
import auth from './auth'
import team from './team'
import eachUser from './eachUser'
import store from './store'
import eachStore from './eachStores'
import inventory from './inventory'
import product from './product'
import eachProduct from './eachProduct'
import brand from './brand'
import scrap from './scrap'
import category from './category'
import saleProduct from './saleProduct'
import eachSaleProduct from './eachSaleProduct'

const rootReducer = combineReducers({
  auth,
  team,
  eachUser,
  store,
  eachStore,
  inventory,
  product,
  eachProduct,
  brand,
  scrap,
  category,
  saleProduct,
  eachSaleProduct,
})

export default rootReducer
