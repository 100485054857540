import { connect } from 'react-redux'
import EditProduct from '../../pages/product/edit'
import { getProductbyID, patchProduct, getBrand } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { eachProduct: state.eachProduct, brand: state.brand }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductbyID: (data, CB) => dispatch(getProductbyID(data, CB)),
    patchProduct: (data, CB) => dispatch(patchProduct(data, CB)),
    getBrand: (CB) => dispatch(getBrand(CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)
