import { connect } from 'react-redux'
import ForgotPassword from '../../pages/auth/forgotPassword'
import {} from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
