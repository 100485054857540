import React from 'react'
import { Table, Button, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const InviteTable = ({ data, postAuthInvite, getTeam, deleteTempAuth }) => {
  const invited_column = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value, data) => (
        <a style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
          <strong style={{ color: '#555' }}>{value}</strong>
        </a>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (value, data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              postAuthInvite({ email: data.email, role: data.role }, (bol) => {
                if (bol) getTeam(() => {})
              })
            }}>
            Resend
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            onClick={() => {
              deleteTempAuth({ _id: data._id }, (bol) => {
                if (bol) getTeam(() => {})
              })
            }}>
            Delete
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Table
      showHeader={false}
      rowKey={(record) => record._id}
      dataSource={data}
      columns={invited_column}
      pagination={false}
    />
  )
}

InviteTable.propTypes = {
  data: PropTypes.array.isRequired,
  postAuthInvite: PropTypes.func.isRequired,
  getTeam: PropTypes.func.isRequired,
  deleteTempAuth: PropTypes.func.isRequired,
}

export default InviteTable
