import { connect } from 'react-redux'
import ChromeXTSaleEdit from '../../../pages/chromext/sale/edit'
import {
  getSaleProductbyID,
  patchSaleProduct,
  addVariantToProduct,
  removeVariantFromProduct,
  editVariantToProduct,
} from '../../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { eachSaleProduct: state.eachSaleProduct }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSaleProductbyID: (id, CB) => dispatch(getSaleProductbyID(id, CB)),
    patchSaleProduct: (data, CB) => dispatch(patchSaleProduct(data, CB)),
    addVariantToProduct: (data, CB) => dispatch(addVariantToProduct(data, CB)),
    editVariantToProduct: (data, CB) => dispatch(editVariantToProduct(data, CB)),
    removeVariantFromProduct: (data, CB) => dispatch(removeVariantFromProduct(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChromeXTSaleEdit)
