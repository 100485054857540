import React, { Component, Fragment } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'

const { Title } = Typography

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`

class Dashboard extends Component {
  state = {}
  render() {
    return (
      <Fragment>
        <Container>
          <Title style={{ opacity: 0.5 }} level={2}>
            UNDER DEVELOPMENT
          </Title>
        </Container>
      </Fragment>
    )
  }
}

export default Dashboard
