import ActionTypes from '../constants/actionTypes'
import initialState from '../stores/initialState'
import jwtDecode from 'jwt-decode'

export default (state = initialState.auth, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      const { payload } = action
      return jwtDecode(payload.token)

    case ActionTypes.REFRESH:
      return jwtDecode(action.payload)

    default:
      return state
  }
}
