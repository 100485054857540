import React from 'react'
import { Form, Input, Select } from 'antd'
import Proptypes from 'prop-types'
import ROLE from '../../../roles'

const { Option } = Select

const InviteForm = ({ form, onInvite, role }) => {
  return (
    <Form
      name="invite-form"
      initialValues={{
        email: '',
        role: '',
      }}
      form={form}
      onFinish={onInvite}>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Select"
        name="role"
        rules={[
          {
            required: true,
            message: 'Please enter the role!',
          },
        ]}>
        <Select>
          {role === ROLE.OWNER.name && <Option value="administrator">Administrator</Option>}
          <Option value="user">User</Option>
        </Select>
      </Form.Item>
    </Form>
  )
}

// InviteForm.propTypes = {
//   from: Proptypes.object.isRequired,
//   onInvite: Proptypes.func.isRequired,
//   role: Proptypes.string.isRequired,
// };

export default InviteForm
