import ActionTypes from '../constants/actionTypes'
import initialState from '../stores/initialState'

export default (state = initialState.brand, action) => {
  switch (action.type) {
    case ActionTypes.BRAND:
      const { payload } = action
      return payload
    default:
      return state
  }
}
