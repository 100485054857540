import React, { useEffect, useState } from 'react'
import { Avatar, Input, Typography, Divider, Button, Dropdown, Menu, message } from 'antd'
import styled from 'styled-components'
import { UserOutlined, SettingOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import TopContainer from '../../../UI/wrapper/topcontainer'
import _ from 'lodash'
import ROLE from '../../../roles'

const { Text } = Typography

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-flow: column;
`
const Container = styled.div`
  margin-top: 40px;
  width: 542px;
  margin-bottom: 40px;
`

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  > span {
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Required = styled.label`
  margin-right: 12px;
  min-width: 100px;

  &::before {
    content: '*';
    color: red;
    margin-right: 2px;
  }
`

const Profile = ({
  history,
  getEachUser,
  eachUser,
  match,
  auth,
  postChangePassword,
  changeStatus,
  deleteAuth,
}) => {
  let email = match.params.email

  const [change, setChange] = useState({
    oldPassword: '',
    password: '',
    password2: '',
  })

  useEffect(() => {
    getEachUser({ email }, (bol) => {})
  }, [email])

  const onChangePassword = () => {
    if (_.isEmpty(change.oldPassword) || _.isEmpty(change.password) || _.isEmpty(change.password2))
      message.error('All field are required', 1)
    else if (change.password !== change.password2) message.error('Password must match', 1)
    else {
      const { oldPassword, password, password2 } = change
      postChangePassword({ oldPassword, password, password2 }, (bol) => {
        if (bol) history.push('/auth/login')
      })
    }
  }

  const onChangeStatus = (_id) => {
    changeStatus({ _id }, (bol) => {
      if (bol) history.push('/team')
    })
  }

  const onDeleteAuth = (_id) => {
    deleteAuth({ _id }, (bol) => {
      if (bol) history.push('/team')
    })
  }

  const menu = (onChangeStatus, onDeleteAuth) => (
    <Menu>
      <Menu.Item key="0">
        <a onClick={() => onChangeStatus(eachUser._id)}>{`${
          eachUser.status ? 'Block' : 'UnBlock'
        } User`}</a>
      </Menu.Item>
      {/* <Menu.Item key="1" >
        <a onClick={() => onDeleteAuth(eachUser._id)}>Delete User</a>
      </Menu.Item> */}
    </Menu>
  )

  return (
    <Wrapper>
      <TopContainer>
        {(auth.role === ROLE.OWNER.name || auth.role === ROLE.ADMINISTRATOR.name) && (
          <Button onClick={() => history.push('/team')}>
            <ArrowLeftOutlined />
          </Button>
        )}

        <div>
          {auth.email !== eachUser.email ? (
            <Dropdown
              overlay={() => menu(onChangeStatus, onDeleteAuth)}
              trigger={['click']}
              placement="bottomRight">
              <Button>
                <SettingOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Button type="primary" style={{ marginLeft: 8 }} disabled>
              Save
            </Button>
          )}
        </div>
      </TopContainer>
      <Avatar size={100} icon={<UserOutlined />} style={{ marginTop: 30, cursor: 'not-allowed' }} />
      <Container>
        <InputContainer>
          <Text strong>Full Name</Text>
          <Input
            style={{ color: '#444444a1' }}
            size="large"
            placeholder="Full Name"
            disabled
            value={eachUser.name}
            // onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <Text strong>Email</Text>
          <Input
            style={{ color: '#444444a1' }}
            size="large"
            placeholder="Email"
            disabled
            value={eachUser.email}
            // onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <Text strong>Role</Text>
          <Input
            style={{ color: '#444444a1' }}
            size="large"
            placeholder="Role"
            disabled
            value={eachUser.role}
            // onChange={(e) => setEmail(e.target.value)}
          />
        </InputContainer>

        {auth.email === eachUser.email && (
          <>
            <Divider orientation="left" plain style={{ marginTop: 100, marginBottom: 40 }}>
              <Text>Password</Text>
            </Divider>
            <InputContainer>
              <Text strong>Old Password</Text>
              <Input
                size="large"
                placeholder=" Old Password"
                value={change.oldPassword}
                type="password"
                onChange={(e) => {
                  let newData = { ...change }
                  newData.oldPassword = e.target.value
                  setChange(newData)
                }}
              />
            </InputContainer>
            <InputContainer>
              <Text strong>New Password</Text>
              <Input
                size="large"
                placeholder="New Password"
                type="password"
                value={change.password}
                onChange={(e) => {
                  let newData = { ...change }
                  newData.password = e.target.value
                  setChange(newData)
                }}
              />
            </InputContainer>
            <InputContainer>
              <Text strong>Confirm Password</Text>
              <Input
                size="large"
                placeholder="Confirm Password"
                type="password"
                value={change.password2}
                onChange={(e) => {
                  let newData = { ...change }
                  newData.password2 = e.target.value
                  setChange(newData)
                }}
              />
            </InputContainer>
            <Button type="danger" onClick={() => onChangePassword()}>
              Change Password
            </Button>
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default Profile
