import React from 'react'
import { List, Image, Card, Upload, Button, Popconfirm } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const data = [
  'https://b.scdn.gr/images/sku_main_images/022118/22118951/20200225105928_huawei_matebook_d_14_r5_3500u_8gb_512gb_fhd_w10_mystic_silver.jpeg',
]

const ImagesUpload = () => {
  return (
    <>
      <List
        header={null}
        footer={null}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Popconfirm
                title="Are you sure to delete this task?"
                // onConfirm={confirm}
                // onCancel={cancel}

                placement="topLeft"
                okText="Yes"
                cancelText="No">
                <Button danger>Delete</Button>
              </Popconfirm>,
            ]}>
            <Card style={{ width: 'fit-content' }} bordered={false}>
              <Image src={item} width={200} />
            </Card>
          </List.Item>
        )}
      />

      <Upload>
        <Button style={{ marginTop: 24 }} icon={<UploadOutlined />}>
          Click to Upload
        </Button>
      </Upload>
    </>
  )
}

export default ImagesUpload
