import React, { useState, Fragment, useEffect } from 'react'
import { Divider, Button, PageHeader, Input, Modal, List, Typography, Drawer, Form } from 'antd'
import { PlusOutlined, FilterOutlined } from '@ant-design/icons'
import ListTable from './listTable'
import { displayPrice } from '../../utils/basic'
import FilterForm from './filterForm'
import { isEmpty } from 'lodash'
import qs from 'qs'

const { Paragraph, Text, Link } = Typography

const { Search } = Input

const Index = ({
  getProduct,
  deleteProduct,
  product,
  onPagination,
  location,
  history,
  getCategory,
  getBrand,
  category,
  brand,
  onClearFilter,
  onFilterApply,
  onSearch,
}) => {
  const [loading, setLoading] = useState(true)
  const [modalVisible, setModal] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState()
  const [storeData, setStoreData] = useState([])
  const [allparams, setAllparams] = useState()
  const [form] = Form.useForm()

  useEffect(() => {
    getProduct((bol) => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (drawerVisible) {
      getCategory((bol) => {})
      getBrand(() => {})
    }
  }, [drawerVisible])

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true })

    setAllparams(params)

    form.setFieldsValue({
      category: params.category,
      brand: params.brand,
      // min_price: params.min_price,
      // max_price: params.max_price,
      // no_store: params.no_store && params.no_store === 'true' ? true : false,
    })

    setSearchTerm(params.term)
  }, [location.search])

  const onPageChange = (page) => onPagination(page, () => {})

  const onEdit = (record) => {
    history.push(`${location.pathname}/edit/${record._id}`)
  }

  const onDelete = (record) => {
    deleteProduct({ _id: record._id }, (bol) => {
      if (bol) {
        setLoading(true)
        getProduct(() => {
          setLoading(false)
        })
      }
    })
  }

  const openDrawer = () => {
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  const onFilter = (value) => {
    let data = {}

    if (!isEmpty(value.category)) data['category'] = value.category
    if (!isEmpty(value.brand)) data['brand'] = value.brand
    if (value.min_price) data['min_price'] = value.min_price
    if (value.max_price) data['max_price'] = value.max_price
    if (value.no_store) data['no_store'] = value.no_store

    if (!isEmpty(data)) {
      setLoading(true)

      onFilterApply(data, () => {
        setLoading(false)
      })
    }
  }

  return (
    <Fragment>
      <PageHeader
        title="Product"
        subTitle={`(${product.length})`}
        extra={[
          <div style={{ display: 'flex', alignItems: 'center' }} key={1}>
            <Search
              key={1}
              style={{ marginRight: 16 }}
              value={searchTerm}
              placeholder={'Search Product ...'}
              onChange={(e) => setSearchTerm(e.target.value)}
              onSearch={() => {
                setLoading(true)
                onSearch(searchTerm, () => {
                  setLoading(false)
                })
              }}
            />

            <Button
              style={{ marginRight: 16 }}
              key={3}
              type="primary"
              onClick={() => history.push(`${location.pathname}/new`)}>
              <PlusOutlined /> Add Product
            </Button>

            <Button key={4} onClick={openDrawer}>
              <FilterOutlined />
            </Button>
          </div>,
        ]}
      />
      <Divider style={{ marginTop: 12 }} />

      <ListTable
        data={product && product.data}
        totalDataLength={product && product.length}
        loading={loading}
        onPageChange={onPageChange}
        onEdit={onEdit}
        onDelete={onDelete}
        setModal={setModal}
        setStoreData={setStoreData}
      />

      <Drawer
        title="Filters"
        placement="right"
        width={400}
        destroyOnClose
        closable={false}
        onClose={closeDrawer}
        visible={drawerVisible}
        footer={
          <div
            style={{
              textAlign: 'center',
              padding: '24px',
            }}>
            <Button
              key={1}
              onClick={() => {
                form.submit()
                // closeDrawer();
              }}
              type="primary"
              style={{ marginRight: 16 }}>
              Apply Filter
            </Button>
            <Button
              key={2}
              onClick={() => {
                setLoading(true)
                onClearFilter(() => {
                  closeDrawer()
                  form.resetFields()
                  setLoading(false)
                })
              }}>
              Clear Filter
            </Button>
          </div>
        }
        footerStyle={{ height: 100 }}>
        <FilterForm
          form={form}
          onFinish={onFilter}
          category={category}
          allparams={allparams}
          brand={brand}
        />
      </Drawer>

      <Modal
        title={`Available at ${storeData.length} Stores`}
        visible={modalVisible}
        onCancel={() => {
          setModal(false)
          setStoreData([])
        }}
        footer={null}
        bodyStyle={{ minHeight: 200 }}>
        <List
          size="small"
          dataSource={storeData}
          bordered
          renderItem={(item) => (
            <List.Item>
              <Link target="_blank" href={item.url}>
                {item.store.name}
              </Link>
              <div>
                <Text type="secondary" style={{ marginRight: 16 }}>
                  {item.updatedAt}
                </Text>
                <Text>{displayPrice(item.price)}</Text>
              </div>
            </List.Item>
          )}
        />
      </Modal>
    </Fragment>
  )
}

export default Index
