import React, { useEffect, useState } from 'react'
import { PageHeader, Typography, Divider, Tabs, Spin, message } from 'antd'
import BasicForm from './form/basic'
import SpecificationForm from './form/specification'
import ImagesUpload from './form/images'
import { pickBy, isEmpty, isString } from 'lodash'
import Specs from './specs'
import Stores from './stores'

const { TabPane } = Tabs
const { Title, Text } = Typography

const TAB = {
  BASIC: 'basic',
  SPECIFICATION: 'specification',
  STORES: 'stores',
  IMAGES: 'images',
}

const Index = ({ history, match, getProductbyID, eachProduct, patchProduct, getBrand, brand }) => {
  const [currentTab, setTab] = useState(TAB.BASIC)
  const [loading, setLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    let ID = match.params.id

    getProductbyID({ id: ID }, (bol, d) => {
      getBrand(() => {
        setLoading(false)
      })
    })
  }, [])

  const onFinish = (values) => {
    const data = {
      tab: currentTab,
      ...pickBy(values, (e) => !isEmpty(e)),
      id: eachProduct._id,
    }

    patchProduct(data, (bol, d) => {
      if (bol) {
        let ID = match.params.id

        setModalVisible(false)
        getProductbyID({ id: ID }, (bol, d) => {
          message.success('updated', 1)
        })
      }
    })
  }

  return (
    <Spin spinning={loading}>
      <PageHeader title="Edit Product" onBack={() => history.goBack()} />
      <Divider style={{ marginTop: 12 }} />

      {!isEmpty(eachProduct) && (
        <Tabs
          type="card"
          onChange={(e) => {
            setTab(e)
          }}
          defaultActiveKey={currentTab}>
          <TabPane tab="Basic" key={TAB.BASIC}>
            <BasicForm onFinish={onFinish} data={eachProduct} brand={brand} />
          </TabPane>
          <TabPane tab="specification" key={TAB.SPECIFICATION}>
            <Specs onFinish={onFinish} specs_data={eachProduct.tech_specs} />
          </TabPane>
          <TabPane tab="Stores" key={TAB.STORES}>
            <Stores
              onFinish={onFinish}
              data={eachProduct.stores}
              setModalVisible={setModalVisible}
              modalVisible={modalVisible}
            />
          </TabPane>
          {/* <TabPane tab="Images" key={TAB.IMAGES}>
            <ImagesUpload />
          </TabPane> */}
          {/* <TabPane tab="SEO" key="5"></TabPane> */}
        </Tabs>
      )}
    </Spin>
  )
}

export default Index
