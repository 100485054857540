import keyMirror from 'keymirror'
export default keyMirror({
  LOGIN: null,
  LOGOUT: null,
  REFRESH: null,

  INVENTORY: null,
  PRODUCT: null,
  EACH_PRODUCT: null,

  CATEGORY: null,
  EACH_CATEGORY: null,

  STORE: null,
  EACH_STORE: null,

  BRAND: null,
  SCRAP: null,

  TEAM: null,
  EACH_USER: null,

  SALE_PRRODUCT: null,
  EACH_SALE_PRODUCT: null,
})
