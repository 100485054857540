import React from 'react'
import { Form, Input, InputNumber, Typography, Button } from 'antd'
import { map } from 'lodash'
import { FormOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
}

const ren = [
  {
    main: 'Processor (CPU)',
    field: [
      { label: 'Manufacturer', name: 'manufacturer', type: 'string' },
      { label: 'Family', name: 'family', type: 'string' },
      { label: 'Model', name: 'model', type: 'string' },
      { label: 'Frequency', name: 'frequency', type: 'string' },
      { label: 'Generation', name: 'generation', type: 'string' },
    ],
  },

  {
    main: 'Screen',
    field: [
      { label: 'IPS Panel', name: 'ips_panel', type: 'string' },
      { label: 'Diagonal', name: 'diagonal', type: 'string' },
      { label: 'Analysis', name: 'analysis', type: 'string' },
      { label: 'Possibilities', name: 'possibilities', type: 'string' },
    ],
  },
  {
    main: 'Memory (RAM)',
    field: [
      { label: 'Memory capacity', name: 'memory_capacity', type: 'string' },
      { label: 'Type', name: 'type', type: 'string' },
    ],
  },

  {
    main: 'Graphics card',
    field: [
      { label: 'Manufacturer', name: 'manufacturer', type: 'string' },
      { label: 'Model', name: 'model', type: 'string' },
      { label: 'Memory', name: 'memory', type: 'string' },
      { label: 'Shared Memory', name: 'shared_memory', type: 'string' },
    ],
  },
  {
    main: 'Hard drive',
    field: [
      { label: 'Memory capacity', name: 'memory_capacity', type: 'string' },
      { label: 'Type', name: 'type', type: 'string' },
    ],
  },

  {
    main: 'Second Hard drive',
    field: [
      { label: 'Memory capacity', name: 'memory_capacity', type: 'string' },
      { label: 'Type', name: 'type', type: 'string' },
    ],
  },

  {
    main: 'Means of Reading',
    field: [
      { label: 'Optical Drive', name: 'optical_drive', type: 'string' },
      { label: 'Card Reader', name: 'card_reader', type: 'boolean' },
    ],
  },
  {
    main: 'Connectivity',
    field: [
      { label: 'Connections', name: 'connections', type: 'string' },
      { label: 'Fingerprint', name: 'fingerprint', type: 'boolean' },
    ],
  },
  {
    main: 'Software',
    field: [{ label: 'Operating system', name: 'os', type: 'string' }],
  },
  {
    main: 'General characteristics',
    field: [
      { label: 'Weight', name: 'weight', type: 'string' },
      { label: 'Type', name: 'type', type: 'string' },
      { label: 'Color', name: 'color', type: 'string' },
    ],
  },
]

const SpecificationForm = ({ form, onFinish }) => {
  return (
    <Form
      name="specification-form"
      {...layout}
      style={{ minWidth: 375, padding: '24px 0' }}
      // form={form}
      initialValues={{ category: 'laptop', rank: 0 }}
      onFinish={onFinish}>
      {map(ren, (r, i) => {
        return (
          <div key={i}>
            <Form.Item
              {...tailLayout}
              name={r.main}
              // label={<FormOutlined />}
              style={{ marginTop: 8 }}>
              <Text style={{ fontSize: 16 }} strong>
                {r.main}
              </Text>
            </Form.Item>

            {map(r.field, (fie, j) => {
              return (
                <Form.Item name={fie.name} label={fie.label} key={j}>
                  <Input />
                </Form.Item>
              )
            })}
          </div>
        )
      })}

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
export default SpecificationForm

// title - required
// Category - required
// ranking  - null
