import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { PageHeader, Button, Card, List, Typography, Popconfirm, Spin } from 'antd'

const Scrap = ({ scrap, getScrap }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getScrap(() => {
      setLoading(false)
    })
  }, [])

  return (
    <>
      <PageHeader
        title="Scrap List"
        subTitle={`(${!isEmpty(scrap) ? scrap.length : 0})`}
        style={{ borderBottom: '1px solid #eee' }}
      />

      <Card bordered={false}>
        <List
          itemLayout="horizontal"
          dataSource={scrap.data}
          loading={loading}
          pagination={true}
          renderItem={(item) => (
            <List.Item
              actions={
                [
                  // <Popconfirm
                  //   key={1}
                  //   placement="left"
                  //   title="Sure to delete?"
                  //   // onConfirm={() => onDelete(item._id)}
                  // >
                  //   <Button type="link" danger>
                  //     Delete
                  //   </Button>
                  // </Popconfirm>,
                ]
              }>
              <List.Item.Meta
                title={
                  <Typography.Text strong>
                    <a href={item.url} target="_blank" rel="noopener nofollow">
                      {item.url}
                    </a>
                  </Typography.Text>
                }
              />

              <Spin size="small" spinning={item.status} />
            </List.Item>
          )}
        />
      </Card>
    </>
  )
}

export default Scrap
