import { connect } from 'react-redux'
import ChromeXTSale from '../../../pages/chromext/sale'
import {
  getSaleProduct,
  deleteSaleProduct,
  patchSaleChangeStatus,
} from '../../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { saleProduct: state.saleProduct }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSaleProduct: (CB) => dispatch(getSaleProduct(CB)),
    deleteSaleProduct: (data, CB) => dispatch(deleteSaleProduct(data, CB)),
    patchSaleChangeStatus: (data, CB) => dispatch(patchSaleChangeStatus(data, CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChromeXTSale)
