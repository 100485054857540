import React from 'react'
import { Table, Button, Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import ProductCard from './productCard'
import ProductDetail from './productDetail'

const ListTable = ({
  data,
  totalDataLength,
  onPageChange,
  loading,
  onEdit,
  onDelete,
  setModal,
  setStoreData,
}) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (value, data) => (
        <ProductCard
          title={data.title}
          // image={data.image[0]}
          brand={data.brand}
          category={data.category}
          series={data.series}
          stores={data.stores}
          setModal={setModal}
          setStoreData={setStoreData}
        />
      ),
    },

    {
      title: 'Action',
      dataIndex: 'operation',
      width: 200,
      render: (_, record) => {
        return (
          <div>
            <Button type="link" style={{ padding: 0 }} onClick={() => onEdit(record)}>
              Edit
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
              <Button style={{ marginLeft: 16, padding: 0 }} type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Table
      showHeader={false}
      rowKey={(record) => record._id}
      loading={loading}
      // bordered={true}
      dataSource={data}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => <ProductDetail data={record.tech_specs} />,
      }}
      pagination={{
        total: totalDataLength,
        hideOnSinglePage: true,
        showSizeChanger: false,
        onChange: onPageChange,
      }}
    />
  )
}

// ListTable.propTypes = {
//   data: PropTypes.array.isRequired,
//   totalDataLength: PropTypes.number.isRequired,
//   loading: PropTypes.bool.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onEdit: PropTypes.func.isRequired,
//   onDelete: PropTypes.func.isRequired,
// };

export default React.memo(ListTable)
