import React, { useState, useEffect } from 'react'
import { PageHeader, Divider, Tabs, Form, Spin, Card, Button, Space, List } from 'antd'
import ProductForm from './form/productForm'
import VarientForm from './form/variantForm'
import { capitalize, pick, isEmpty, pickBy, isString, identity, each } from 'lodash'
import moment from 'moment'

const { TabPane } = Tabs

const TAB = {
  PRODUCT: 'product',
  VARIANT: 'variant',
}

const OPERATION = {
  ADD: 'add',
  EDIT: 'edit',
}

const EditSaleProduct = ({
  history,
  getSaleProductbyID,
  match,
  eachSaleProduct,
  patchSaleProduct,
  addVariantToProduct,
  removeVariantFromProduct,
  editVariantToProduct,
}) => {
  const [currentTab, setTab] = useState(TAB.PRODUCT)
  const [loading, setLoading] = useState(true)
  const [selectedId, setSelectedId] = useState()
  const [form] = Form.useForm()

  useEffect(() => {
    getSaleProductbyID(match.params.id, () => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(eachSaleProduct)) {
      form.setFieldsValue({
        name: eachSaleProduct.name,
        site: eachSaleProduct.site,
        date: eachSaleProduct?.date ? moment(eachSaleProduct.date) : '',
        position: eachSaleProduct?.position ?? '',
        badge: eachSaleProduct?.badge ?? '',
      })
    }
  }, [eachSaleProduct])

  const onFinish = (value) => {
    setLoading(true)

    patchSaleProduct(
      {
        name: value?.name,
        site: value?.site,
        date: value?.date?._d ?? '',
        position: value?.position ?? '',
        badge: value?.badge ?? '',
        id: eachSaleProduct?._id,
      },
      (bol) => {
        setLoading(false)
      }
    )
  }

  const onFinishVariant = (value) => {
    let data = pickBy(pick(value, ['color', 'colorCode', 'variantType', 'url']), isString)

    if (value.operation === OPERATION.ADD) {
      setLoading(true)
      addVariantToProduct({ ...data, id: eachSaleProduct._id }, (bol) => {
        if (bol)
          getSaleProductbyID(match.params.id, () => {
            setLoading(false)
          })
        else setLoading(false)
      })
    } else if (value.operation === OPERATION.EDIT) {
      setLoading(true)
      editVariantToProduct({ ...data, id: eachSaleProduct._id, variantID: selectedId }, (bol) => {
        if (bol)
          getSaleProductbyID(match.params.id, () => {
            setLoading(false)
            setSelectedId()
            form.resetFields()
          })
        else setLoading(false)
      })
    } else console.error('wrong opearion selected')
  }

  const onDeleteVariant = (id) => {
    setLoading(true)
    removeVariantFromProduct({ variantID: id, id: eachSaleProduct._id }, (bol) => {
      if (bol)
        getSaleProductbyID(match.params.id, () => {
          setLoading(false)
        })
      else setLoading(false)
    })
  }

  return (
    <Spin spinning={loading}>
      <PageHeader title="Edit Sale Product" onBack={() => history.push('/chromext/sale')} />
      <Divider style={{ marginTop: 12 }} />

      <Tabs type="card" onChange={(e) => setTab(e)} defaultActiveKey={currentTab}>
        <TabPane tab={capitalize(TAB.PRODUCT)} key={TAB.PRODUCT}>
          <ProductForm
            form={form}
            type={TAB.PRODUCT}
            onFinish={onFinish}
            data={pick(eachSaleProduct, ['name', 'site', 'date'])}
          />
        </TabPane>

        <TabPane tab={capitalize(TAB.VARIANT)} key={TAB.VARIANT}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <List
              header={null}
              footer={null}
              bordered
              dataSource={eachSaleProduct?.variant ?? []}
              renderItem={(item) => (
                <>
                  <List.Item
                    key={item._id}
                    actions={[
                      <Button
                        onClick={() => {
                          if (item._id === selectedId) {
                            setSelectedId()
                            form.resetFields()
                          } else {
                            setSelectedId(item._id)
                            form.setFieldsValue({
                              variantType: item?.variantType,
                              url: item?.url,
                              color: item?.color,
                              colorCode: item?.colorCode,
                            })
                          }
                        }}>
                        Edit
                      </Button>,
                      <Button danger onClick={() => onDeleteVariant(item._id)}>
                        Delete
                      </Button>,
                    ]}>
                    <Button
                      type="link"
                      href={item.url}
                      target="_blank"
                      style={{ marginBottom: 0 }}>{`${capitalize(
                      item?.color ?? 'No Color Data'
                    )} | ${item?.variantType ?? 'No Variant Data'}`}</Button>
                  </List.Item>

                  {item._id === selectedId && (
                    <VarientForm
                      operation={OPERATION.EDIT}
                      form={form}
                      onFinish={(value) => onFinishVariant({ ...value, operation: OPERATION.EDIT })}
                    />
                  )}
                </>
              )}
            />
            {isEmpty(selectedId) && (
              <Card>
                <VarientForm
                  operation={OPERATION.ADD}
                  form={form}
                  onFinish={(value) => onFinishVariant({ ...value, operation: OPERATION.ADD })}
                />
              </Card>
            )}
          </Space>
        </TabPane>
      </Tabs>
    </Spin>
  )
}

export default EditSaleProduct
