import React from 'react'
import { Form, Input, Button, Checkbox } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import Proptypes from 'prop-types'

const SignupForm = ({ email, onSignup, onError }) => {
  return (
    <Form
      style={{ minWidth: 375 }}
      name="signup-form"
      initialValues={{
        remember: true,
        email: email,
        name: '',
        password: '',
        password2: '',
      }}
      onFinish={onSignup}
      onFinishFailed={onError}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}>
        <Input
          disabled
          size="large"
          prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="email"
        />
      </Form.Item>

      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your name!',
          },
        ]}>
        <Input
          size="large"
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Name"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}>
        <Input.Password
          size="large"
          placeholder="Password"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      </Form.Item>

      <Form.Item
        name="password2"
        dependencies={['password']}
        // hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('The two passwords do not match!')
            },
          }),
        ]}>
        <Input.Password
          size="large"
          placeholder="Confirm Password"
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 8 }}>
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} size="large">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

SignupForm.propTypes = Proptypes.exact({
  email: Proptypes.string.isRequired,
  onSignup: Proptypes.func.isRequired,
  onError: Proptypes.func.isRequired,
})

export default SignupForm
