import React from 'react'
import { Table, Button, Popconfirm, Space } from 'antd'
import PropTypes from 'prop-types'
import ProductCard from './productCard'
import ProductDetail from './productDetail'

const ListTable = ({
  data,
  totalDataLength,
  onPageChange,
  loading,
  onEdit,
  onDelete,
  setModal,
  setStoreData,
  onChangeStatus,
}) => {
  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',

      render: (value, data) => (
        <ProductCard
          name={data?.name ?? ''}
          site={data?.site ?? ''}
          date={data?.date ?? ''}
          status={data?.status ?? true}
        />
      ),
    },

    {
      title: 'Action',
      dataIndex: 'operation',
      width: 200,
      render: (_, record) => {
        return (
          <Space>
            <Button onClick={() => onChangeStatus(record)}>
              {record.status ? 'Disable ' : 'Enable'}
            </Button>
            <Button disabled={!record?.status ?? false} onClick={() => onEdit(record)}>
              Edit
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
              <Button danger disabled={record?.variant?.length > 0 || !record?.status}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      showHeader={false}
      rowKey={(record) => record._id}
      loading={loading}
      // bordered={true}
      dataSource={data ?? []}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => <ProductDetail data={record.variant} />,
      }}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  )
}

export default React.memo(ListTable)
