import React from 'react'
import { Form, Input, Button } from 'antd'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
}

const AddProduct = ({ onFinish, form, operation }) => {
  return (
    <Form
      name="variant-form"
      {...layout}
      style={{ minWidth: 375, padding: '24px 0' }}
      form={form}
      onFinish={onFinish}>
      <Form.Item
        name="variantType"
        label="Varient Type"
        rules={[
          {
            required: true,
            message: 'Please Enter Varient Information!',
          },
        ]}>
        <Input placeholder={'Enter Variant .. example: 2GB 64GB'} />
      </Form.Item>

      <Form.Item
        name="color"
        label="Color"
        rules={[
          {
            required: true,
            message: 'Color is Required!',
          },
        ]}>
        <Input placeholder="Enter Color" />
      </Form.Item>

      <Form.Item name="colorCode" label="Color Code">
        <Input placeholder="Enter Color Code (optional)" />
      </Form.Item>

      <Form.Item
        name="url"
        label="Product URL"
        rules={[
          {
            required: true,
            message: 'Product URL is Required!',
          },

          {
            type: 'url',
            message: 'URL is not Valid!',
          },
        ]}>
        <Input placeholder={'Enter Product URL'} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          {operation === 'add' ? 'Add New Variant' : 'Edit Variant'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default React.memo(AddProduct)
