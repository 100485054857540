import React from 'react'
import { Form, Input } from 'antd'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

const AddStore = ({ onFinish, form }) => {
  return (
    <Form
      name="addstore-form"
      {...layout}
      style={{ minWidth: 375, padding: '24px 0' }}
      form={form}
      onFinish={onFinish}>
      <Form.Item
        name="store"
        label="Store ID"
        rules={[
          {
            required: true,
            message: 'Please input Store ID!',
          },
        ]}>
        <Input />
      </Form.Item>
    </Form>
  )
}

export default React.memo(AddStore)
