import { connect } from 'react-redux'
import Scrap from '../../pages/scrap'
import { getScrap, postScrap } from '../../actions/asyncAction'

const mapStateToProps = (state) => {
  return { scrap: state.scrap }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postScrap: (data, CB) => dispatch(postScrap(data, CB)),
    getScrap: (CB) => dispatch(getScrap(CB)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scrap)
